import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    htmlFontSize: 10,
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none !important',
        fontSize: '1.4rem',
        fontWeight: 700,
        padding: '10px 15px',
        textDecoration: 'none',
        textTransform: 'none',
      },
      contained: {
        color: '#fff',
      },
      outlined: {
        borderWidth: '2px !important',
        padding: '8px 15px',
        '&:hover': {
          color: '#fff',
        }
      },
    },
  }
})

export default theme