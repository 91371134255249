import React, { useState } from "react"
import { Link } from "gatsby"
import * as R from 'ramda'
import PropTypes from "prop-types"
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import { useTheme } from '@material-ui/styles'
import slugify from 'slugify'
import KeyFlagImg from '../images/suomalpalvelua_sin.png'

import styles from './header.module.scss'

const Header = ({ data, noHero }) => {
  const [mobileNavVisibility, setMobileNavVisibility] = useState(false)
  const color = useTheme().palette.primary.main;

  const logoType = R.path(['brand', 'logo', 'file', 'contentType'], data)
  const logoAspectRatio = R.path(['brand', 'logo', 'file', 'details', 'image', 'width'], data) / R.path(['brand', 'logo', 'file', 'details', 'image', 'height'], data)

  const headerBtn1txt = R.path(['brand', 'ylapalkinNappi1Teksti'], data)
  const headerBtn1url = R.path(['brand', 'ylapalkinNappi1Osoite'], data)
  const headerBtn2txt = R.path(['brand', 'ylapalkinNappi2Teksti'], data)
  const headerBtn2url = R.path(['brand', 'ylapalkinNappi2Osoite'], data)

  const headerButtons = (headerBtn1txt || headerBtn1url || headerBtn2txt || headerBtn2url) ? [
    (headerBtn1txt && headerBtn1txt) ? {
      url: headerBtn1url,
      txt: headerBtn1txt
    } : null,
    (headerBtn2url && headerBtn2txt) ? {
      url: headerBtn2url,
      txt: headerBtn2txt
    } : null
  ].filter(el => { return el !== null }) : null

  return (
    <div className={`${styles.root} ${noHero===1?styles.noHero:null}`}>
      <Container maxWidth="lg" style={{position:'relative'}}> 
        <Box className={`${styles.logo} ${logoType.includes('svg') ? styles.svg : null} ${logoAspectRatio < 1 ? styles.vertical : null}`}>
          <Link to="/">
            <img src={R.path(['brand', 'logo', 'file', 'url'], data)} alt={R.path(['brand', 'nimi'], data)} />
          </Link>
          {['foodandevents', 'central', 'mestaritalli', 'sampo'].includes(process.env.GATSBY_BRAND) && 
            <a href="https://www.foodandevents.fi/avainlippu/" style={{marginLeft: '30px'}} target="_blank">
              <img src={KeyFlagImg} alt="Key flag" style={{all: 'unset', width: 50, height: 49}}/>
            </a>
          }
          <span className={styles.navToggle} onClick={(e) => setMobileNavVisibility(!mobileNavVisibility)}>
            <span />
            <span />
            <span />
          </span>
        </Box>

        {R.path(['brand', 'paanavigaatio'], data) && <nav className={styles.nav}>
          {R.path(['brand', 'paanavigaatio'], data).map(item =>
            <Link to={`/${R.path(['sivupolku'], item)}`} key={`/${R.path(['id'], item)}`} partiallyActive={true} className={`nav-${slugify(R.path(['sivupolku'], item))}`} activeClassName={styles.active} activeStyle={{ borderBottomColor: color }}>
              {R.path(['naviOtsikko'], item)}
            </Link>
          )}
        </nav>}             
        <div className={styles.headerRight}>
          <Box pt={1}>
            {(R.path(['brand', 'sivupolku'], data) === 'rax') &&
              <>
                <Link to="/">FI</Link>
                <Link to="/sv">SV</Link>
                <Link to="/en">EN</Link>
                <Link to="/ru">RU</Link>
              </>
            }
            {(R.path(['brand', 'sivupolku'], data) === 'taco-bell') &&
              <>
                <Link to="/">FI</Link>
                <Link to="/en">EN</Link>
              </>
            }
            <Link to="/haku" className={styles.search}><SearchIcon />Haku</Link>

            {headerButtons &&
              <Box ml={3} display="inline">
                {headerButtons.map((btn, i) =>
                  <a key={btn.url} href={btn.url} className={`header-button-${i}`}>
                    <Button variant="outlined" color="primary">
                      {btn.txt}
                    </Button>
                  </a>
                )}
              </Box>}
          </Box>
        </div>
      </Container>          
      {mobileNavVisibility && <Container className={`${styles.mobileNav} ${logoAspectRatio < 1 ? styles.vertical : null}`}>
        {R.path(['brand', 'paanavigaatio'], data) && <nav className={styles.nav}>
          {R.path(['brand', 'paanavigaatio'], data).map(item =>
            <div key={`/${R.path(['id'], item)}`}>
              <Link to={`/${R.path(['sivupolku'], item)}`} partiallyActive={true} className={`nav-${slugify(R.path(['sivupolku'], item))}`} activeClassName={styles.active} activeStyle={{ borderBottomColor: color }}>
              {R.path(['naviOtsikko'], item)}
            </Link>
            </div>
          )}
        </nav>}

        {headerButtons && <>
          {headerButtons.map((btn, i) =>
            <a key={btn.url} href={btn.url} className={`header-button-${i}`}>
              <Button variant="outlined" color="primary">
                {btn.txt}
              </Button>
            </a>
          )}
        </>}
        <div>
          <Link to="/haku" className={styles.search}><SearchIcon />Haku</Link>
          {(R.path(['brand', 'sivupolku'], data) === 'rax') &&
            <div>
              <Link to="/">FI</Link>
              <Link to="/sv">SV</Link>
              <Link to="/en">EN</Link>
              <Link to="/ru">RU</Link>
            </div>
          }
        </div>
      </Container>}
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
