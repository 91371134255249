/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

function SEO({
  layout,
  sitename,
  seoTitle,
  seoDesc,
  someTitle,
  someDesc,
  image,
  url,
  lang,
  published,
  modified,
  meta,
  bodyClass,
  pageTemplate,
  date,
  jsonLd,
  dataLayer,
  service,
  cuisine,
  city,
  goal,
  asiakaspolku
}) {

  const gtmObject = {
    event: 'pageview',
    brand: sitename,
    service: service,
    cuisine: cuisine,
    // publishdata: date,
    city: city,
    template: pageTemplate,
    goal: goal,
    asiakaspolku: asiakaspolku,
  };

  if (typeof window !== 'undefined' && !layout && dataLayer) {
    if (window.dataLayer) {
      window.dataLayer.push(gtmObject);
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
        itemscope: '',
        itemtype: 'http://schema.org/Article'
      }}
      bodyAttributes={bodyClass ? {
        class: bodyClass
      } : {}}
      title={seoTitle}
      titleTemplate={sitename ? `%s | ${sitename}` : seoTitle}
      meta={[
        {
          itemprop: `name`,
          content: seoTitle,
        },
        {
          name: `description`,
          content: seoDesc,
        },
        {
          itemprop: `description`,
          content: seoDesc,
        },
        {
          itemprop: `image`,
          content: image,
        },
        {
          property: `og:title`,
          content: someTitle,
        },
        someDesc ? {
          property: `og:description`,
          content: someDesc,
        } : {},
        {
          property: `og:type`,
          content: `article`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:site_name`,
          content: sitename,
        },
        {
          property: `article:published_time`,
          content: published,
        },
        {
          property: `article:modified_time`,
          content: modified,
        },
      ].concat(meta)}
    >
      {jsonLd && <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>}
    </Helmet>
  )
}

SEO.defaultProps = {
  layout: false,
  // sitename: 'Restel',
  lang: 'fi',
  meta: [],
  image: '',
  published: '',
  modified: '',
  pageTemplate: '',
  date: '',
  jsonLd: {},
  dataLayer: false,
  service: '',
  cuisine: '',
  city: '',
  goal: '',
  asiakaspolku: '',
}

SEO.propTypes = {
  layout: PropTypes.bool,
  sitename: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDesc: PropTypes.string,
  someTitle: PropTypes.string,
  someDesc: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  lang: PropTypes.string,
  published: PropTypes.string,
  modified: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  pageTemplate: PropTypes.string,
  date: PropTypes.string,
  jsonLd: PropTypes.object,
  dataLayer: PropTypes.bool,
  service: PropTypes.string,
  cuisine: PropTypes.string,
  city: PropTypes.string,
  goal: PropTypes.string,
  asiakaspolku: PropTypes.string,
}

export default SEO
