import React from "react"
import { Link } from "gatsby"
import * as R from 'ramda'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import EcocompassImg from '../images/ekokompassi_logo_white.png'
import KeyFlagNegImg from '../images/suomalpalvelua_valknega.png'
import KeyFlagImg from '../images/suomalpalvelua_sin.png'

import styles from './footer.module.scss'

const Footer = ({ data }) => {
  const footerData = R.path(['brand'], data)
  const brand = R.path(['nimi'], footerData).toLowerCase()
  const contactPage = Boolean(R.path(['contactPage'], data))
  const jobPage = Boolean(R.path(['jobPage'], data))
  const restaurantsPage = R.path(['ravintoloidenYlasivu', 'sivupolku'], footerData)
  const multipleRestaurants = Boolean(R.path(['restaurants'], data).length > 1)

  const logoAspectRatio = R.path(['logo', 'file', 'details', 'image', 'width'], footerData) / R.path(['logo', 'file', 'details', 'image', 'height'], footerData)

  const instagramArray = R.path(['instagram'], footerData) ? R.path(['instagram'], footerData).split(',') : []

  return (
    <Box bgcolor={R.path(['alapalkinTaustavari'],footerData) ? R.path(['alapalkinTaustavari'],footerData) : '#f9f9f9'} className={`${styles.root} ${R.path(['alapalkinTekstinVari'], footerData) === 'Vaalea' ? styles.lightText : null}`}>
      <Container>
        <Box pb={3} className={`${styles.logo} ${logoAspectRatio < 1 ? styles.vertical : null} ${(logoAspectRatio > 1 && logoAspectRatio < 1.65) ? styles.square : null} ${(logoAspectRatio > 8) ? styles.horizontal : null}`}>
          <Link to="/">
            <img src={R.path(['alapalkinValkoinenLogo', 'file', 'url'], footerData) ? R.path(['alapalkinValkoinenLogo', 'file', 'url'], footerData) : R.path(['logo', 'file', 'url'], footerData)} alt={R.path(['nimi'], footerData)} />
          </Link>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
              {contactPage && <Link to="/yhteystiedot">Yhteystiedot</Link>}
              {jobPage && <Link to="/tyopaikat">Töihin</Link>}
              {!jobPage && <a href="https://www.restel.fi/tyonhakijalle" target="_blank" rel="noreferrer noopener">Töihin</a>}
              {restaurantsPage && multipleRestaurants && <Link to={`/${restaurantsPage}`}>Ravintolat</Link>}
          </Grid>
          <Grid item xs={12} sm={2}>
            {brand !== 'lastu' &&
              <a href={`https://www.oivahymy.fi/hae-yrityksia/#text=restel%20${brand === 'muikkuravintola sampo' ? 'sampo' : brand}`} target="_blank" rel="noopener noreferrer">Oiva-raportit</a>
            }   
            {brand === 'lastu' &&
              <a href={`https://www.oivahymy.fi/hae-yrityksia/#text=Lahden%20sibeliustalo`} target="_blank" rel="noopener noreferrer">Oiva-raportit</a>
            }   
            <a href="https://www.restel.fi/sivujen-kayttoehdot" target="_blank" rel="noreferrer noopener">Sivujen käyttöehdot</a>
            <a href="https://www.restel.fi/tietosuojaseloste" target="_blank" rel="noreferrer noopener">Tietosuojaehdot</a>
            <a href="https://www.restel.fi/gdpr-lomake" target="_blank" rel="noreferrer noopener">Rekisteritietojen tarkastuspyyntö</a>
            <span onClick={() => window && window.Cookiebot && window.Cookiebot.show()}>Muuta hyväksyntääsi</span>
          </Grid>
          <Grid item xs={12} sm={3} className={styles.someColumn}>
            {R.path(['facebook'], footerData) && <a href={`https://www.facebook.com/${R.path(['facebook'], footerData)}`} target="_blank" rel="noreferrer noopener"><FacebookIcon className={styles.someIcon} /> facebook.com/{R.path(['facebook'], footerData)}</a>}
            {R.path(['instagram'], footerData) && <span className={styles.instagram}><InstagramIcon className={styles.someIcon} />{instagramArray.map(account => <a href={`https://www.instagram.com/${account}`} target="_blank" rel="noreferrer noopener" key={account}> @{account}</a>)}</span>}
          </Grid>
          <Grid item xs={12} sm={3} className={styles.someColumn}>
            {['foodandevents'].includes(process.env.GATSBY_BRAND) && <a href="https://www.foodandevents.fi/ekokompassi/" target="_blank" rel="noreferrer noopener"><img src={EcocompassImg} alt="Ecocompass certificate" width="180px" /></a>}
          </Grid>
          <Grid item xs={12} sm={2} className={styles.someColumn}>
          {['foodandevents', 'central'].includes(process.env.GATSBY_BRAND) && <a href="https://www.foodandevents.fi/avainlippu/" target="_blank" rel="noreferrer noopener"><img src={KeyFlagNegImg} alt="Key flag" width="50px"/></a>}
          {['mestaritalli', 'sampo'].includes(process.env.GATSBY_BRAND) && <a href="https://www.foodandevents.fi/avainlippu/" target="_blank" rel="noreferrer noopener"><img src={KeyFlagImg} alt="Key flag" width="50px"/></a>}
          </Grid>
        </Grid>
      </Container>
    
  </Box>
)}


export default Footer
