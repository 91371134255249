/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import * as R from 'ramda'

import { ThemeProvider } from '@material-ui/styles'
import DateFnsUtils from '@date-io/date-fns'
// import fiLocale from 'date-fns/locale/fi';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import SEO from './seo'
import RestelTheme from './theme'
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ noHero, children }) => {
  const data = useStaticQuery(graphql`
    query {
      brands: allContentfulKetju {
        nodes {
          sivupolku
          nimi
          vari
          logo {
            file {
              contentType
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          facebook
          instagram
          paanavigaatio {
            id
            sivupolku
            naviOtsikko
          }
          ravintoloidenYlasivu {
            sivupolku
          }
          ylapalkinNappi1Teksti
          ylapalkinNappi1Osoite
          ylapalkinNappi2Teksti
          ylapalkinNappi2Osoite
          alapalkinTaustavari
          alapalkinTekstinVari
          alapalkinValkoinenLogo {
            file {
              contentType
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
        }
      }
      allRestaurants: allContentfulRavintola {
        nodes {
          ketju {
            sivupolku
          }
        }
      }
      allContactPages: allContentfulYleissivu(filter: {sivupolku: {eq: "yhteystiedot"}}) {
        nodes {
          ketju {
            sivupolku
          }
        }
      }
      allJobPages: allContentfulYleissivu(filter: {sivupolku: {eq: "tyopaikat"}}) {
        nodes {
          ketju {
            sivupolku
          }
        }
      }
    }
  `)

  data.brand = data.brands.nodes.find(n => {
    return n && n.sivupolku === process.env.GATSBY_BRAND;
  });
  data.restaurants = data.allRestaurants.nodes.filter(n => {
    return n && n.ketju && n.ketju.sivupolku === process.env.GATSBY_BRAND;
  });
  data.contactPage = data.allContactPages.nodes.find(n => {
    return n && n.ketju && n.ketju.sivupolku === process.env.GATSBY_BRAND;
  });
  data.jobPage = data.allJobPages.nodes.find(n => {
    return n && n.ketju && n.ketju.sivupolku === process.env.GATSBY_BRAND;
  });

  const brandColor = R.path(['brand', 'vari'], data);

  var Color = require('color');

  return (
    <ThemeProvider theme={
        brandColor ? {
          ...RestelTheme,
          palette: {
            ...RestelTheme.palette,
            primary: {
              ...RestelTheme.palette.primary,
              main: brandColor,
            },
          },
          overrides: {
            MuiButton: {
              ...RestelTheme.overrides.MuiButton,
              root: {
                ...RestelTheme.overrides.MuiButton.root,
                [RestelTheme.breakpoints.up('md')]: {
                  minWidth: '160px',
                },
              },
              containedPrimary: {
                ...RestelTheme.overrides.MuiButton.containedPrimary,
                backgroundColor: brandColor,
                '&:hover': {
                  backgroundColor: Color(brandColor).lighten(0.15).string(),
                }
              },
              outlinedPrimary: {
                ...RestelTheme.overrides.MuiButton.outlinedPrimary,
                borderColor: brandColor,
                '&:hover': {
                  backgroundColor: brandColor,
                }
              }
            }
          }
        } : RestelTheme
      }>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SEO layout={true} sitename={R.path(['brand', 'nimi'], data)} seoTitle={R.path(['brand', 'nimi'], data)} bodyClass={R.path(['brand', 'sivupolku'], data)} />
        <Header data={data} noHero={noHero?1:0} />
        <div className="flexwrapper">
          {children}
        </div>
        <Footer data={data} />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout